import {
  HomeOutlined, BarsOutlined, ProfileOutlined, DeploymentUnitOutlined, EyeOutlined, ApartmentOutlined,
  RiseOutlined, SafetyOutlined, BankOutlined, TagOutlined, PullRequestOutlined, ClusterOutlined,CustomerServiceOutlined,
  CodeOutlined,UserSwitchOutlined,TeamOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const homeNavTree = [
  {
    key: 'home',
    path: `${APP_PREFIX_PATH}/home`,
    title: 'home',
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: []
  }
]

const aboutUsNavTree = [{
  key: 'about-us',
  path: `${APP_PREFIX_PATH}/about-us`,
  title: 'About Us',
  icon: BarsOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'about-us-company-profile',
      path: `${APP_PREFIX_PATH}/about-us/company-profile`,
      title: 'Company Profile',
      icon: ProfileOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'about-us-vision-mission-values',
      path: `${APP_PREFIX_PATH}/about-us/vision-mission-values`,
      title: 'Visio,Mission,Values',
      icon: EyeOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const careersNavTree = [{
  key: 'careers',
  path: `${APP_PREFIX_PATH}/careers`,
  title: 'Careers',
  icon: DeploymentUnitOutlined,
  breadcrumb: false,
  submenu: []
}]

const industriesNavTree = [
  {
    key: 'industries',
    path: `${APP_PREFIX_PATH}/industries`,
    title: 'Industries',
    icon: ApartmentOutlined,
    breadcrumb: true,
    submenu: [
      {
        key: 'industries-energy-and-utilities',
        path: `${APP_PREFIX_PATH}/industries/energy-and-utilities`,
        title: 'Energy & Utilities',
        icon: RiseOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'industries-insurance',
        path: `${APP_PREFIX_PATH}/industries/insurance`,
        title: 'Insurance',
        icon: SafetyOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'industries-local-government',
        path: `${APP_PREFIX_PATH}/industries/local-government`,
        title: 'Local Government',
        icon: BankOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'industries-retail',
        path: `${APP_PREFIX_PATH}/industries/retail`,
        title: 'Retail',
        icon: TagOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'industries-telecommunication',
        path: `${APP_PREFIX_PATH}/industries/telecommunication`,
        title: 'Telecommunication',
        icon: ClusterOutlined,
        breadcrumb: false,
        submenu: []
      },
      {
        key: 'industries-transportation',
        path: `${APP_PREFIX_PATH}/industries/transportation`,
        title: 'Transportation',
        icon: PullRequestOutlined,
        breadcrumb: false,
        submenu: []
      }
    ]
  }]

const servicesNavTree = [{
  key: 'services',
  path: `${APP_PREFIX_PATH}/services`,
  title: 'Services',
  icon: BarsOutlined,
  breadcrumb: true,
  submenu: [
    {
      key: 'services-application-development',
      path: `${APP_PREFIX_PATH}/services/application-development`,
      title: 'Application Development Services',
      icon: CodeOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'services-consultancy',
      path: `${APP_PREFIX_PATH}/services/consultancy`,
      title: 'Consultancy Services',
      icon: UserSwitchOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'services-staffing',
      path: `${APP_PREFIX_PATH}/services/staffing`,
      title: 'Staffing Services',
      icon: TeamOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

const contactNavTree = [{
  key: 'contact',
  path: `${APP_PREFIX_PATH}/contact`,
  title: 'Contact',
  icon: CustomerServiceOutlined,
  breadcrumb: false,
  submenu: []
}]
const navigationConfig = [
  ...homeNavTree,
  ...aboutUsNavTree,
  ...industriesNavTree,
  ...careersNavTree,
  ...servicesNavTree,
  ...contactNavTree
]

export default navigationConfig;
