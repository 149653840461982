import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/about-us`} component={lazy(() => import(`./about-us`))} />
        <Route path={`${APP_PREFIX_PATH}/industries`} component={lazy(() => import(`./industries`))} />
        <Route path={`${APP_PREFIX_PATH}/services`} component={lazy(() => import(`./services`))} />
        <Route path={`${APP_PREFIX_PATH}/careers`} component={lazy(() => import(`./careers`))} />
        <Route path={`${APP_PREFIX_PATH}/contact`} component={lazy(() => import(`./contact`))} />
        
        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);